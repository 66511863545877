import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { Router } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "./common/helpers/Loader/Loader";
import {
  getToken,
  isProd,
  login,
  setAuthAccounts,
  setAuthInstance,
} from "./config";
import { useSessionContext } from "./contexts/SessionContext";
import SessionClient from "./services/SessionClient";
import { getSessionCookieData } from "./services/sessionCookie";
import NewSite from "./views/Routes";

const sessionClient = new SessionClient();

const AuthenticatedRoutes = ({ history }) => {
  // There are two sessions managed in the app.
  // The first is the IDP session, handled for us by a context provider and this hook gives us details
  // Placed in session storage

  // We use info from the IDP to create a second session with the management portal API.
  // The endpoint sets a session cookie that we can read, and all endpoints reset it after expiry, so no need to check ourselves.
  const [sessionLoading, setSessionLoading] = useState(true);

  const [access_token, setAccess_token] = useState();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const { setUserRoles, setIsRoleBasedAccess } = useSessionContext();

  useEffect(() => {
    setAuthInstance(instance);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setAuthAccounts(accounts);
      if (accessPermittedFlag()) {
        setUserRoles(accounts[0].idTokenClaims.roles);
        setIsRoleBasedAccess(true);
      } else {
        setUserRoles([]);
        setIsRoleBasedAccess(false);
      }
    } else {
      if (!(accounts && accounts[0])) login();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const accessPermittedFlag = () => {
    if (
      accounts &&
      accounts[0] &&
      accounts[0].idTokenClaims &&
      accounts[0].idTokenClaims.roles
    ) {
      return accounts[0].idTokenClaims.roles.includes("AccessPermitted");
    } else {
      return false;
    }
  };

  useEffect(() => {
    document.title = `${isProd() ? "" : "(Non-prod) "}Nectar management portal`;

    const createSession = async () => {
      setSessionLoading(true);
      try {
        // This call returns nothing, but it sets a non-http-only cookie
        await sessionClient.createSession(access_token);
      } catch (err) {
        alert("Failed to create session with Management Portal API");
        window.location = "/";
      }
      setSessionLoading(false);
    };

    if (access_token) createSession();

    // Refresh the management session cookie on start up and whenever we get a new session with the IDP
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token]);

  useEffect(() => {
    const getAzureToken = async () => {
      const response = await getToken();
      if (response) {
        setAccess_token(response.idToken);
      }
    };

    if (isAuthenticated) getAzureToken();

    // Refresh the management session cookie on start up and whenever we get a new session with the IDP
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // We may already have an API session cookie but pages expect one, so don't render if we don't have one
  const sessionData = getSessionCookieData();
  if (!sessionData) {
    if (sessionLoading) {
      return <Loader />;
    } else {
      return "Failed to create a session with the management portal API";
    }
  }

  return <NewSite />;
};

const Routes = (props) => (
  <Router {...props}>
    <AuthenticatedRoutes history={props.history} />
    <ToastContainer theme="dark" autoClose={6000} />
  </Router>
);

export default Routes;
