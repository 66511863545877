import React from "react";
import { Link, useHistory } from "react-router-dom";

import {
  Header,
  HeaderDivider,
  HeaderLogo,
  HeaderMainBar,
  HeaderNav,
} from "@jsluna/header";
import { Account } from "@jsluna/icons";
import { Sainsburys } from "@jsluna/images";
import {
  ListGroup,
  ListGroupDropdown,
  ListGroupItem,
  ListItem,
} from "@jsluna/list";

import { ValidatePermission } from "../common/userPermissionsCheck";
import { getSessionCookieData } from "../services/sessionCookie";
import { signOutRedirect } from "./../config";

const OurHeader = () => {
  const { username } = getSessionCookieData();
  const history = useHistory();
  const pathname = history.location.pathname;

  return (
    <Header>
      <HeaderMainBar style={{ maxWidth: "100%" }}>
        <HeaderLogo element={Link} to="/">
          <Sainsburys
            aria-label="Sainsbury's logo"
            style={{ verticalAlign: "middle", height: "24px" }}
          />
        </HeaderLogo>
        <HeaderDivider />
        <HeaderNav drawer openDropdownOnHover>
          <ListGroup
            style={{
              display: ValidatePermission("customers") ? "" : "none",
            }}
            active={pathname.startsWith("/customers")}
          >
            <Link to="/customers">
              <ListItem>Customers</ListItem>
            </Link>
          </ListGroup>
          <ListGroup
            style={{
              display: ValidatePermission("marketing-events") ? "" : "none",
            }}
            active={pathname.startsWith("/marketing-events")}
          >
            <Link to="/marketing-events">
              <ListItem>Marketing Events</ListItem>
            </Link>
          </ListGroup>
          <ListGroup
            style={{
              display: ValidatePermission("campaigns") ? "" : "none",
            }}
            active={pathname.startsWith("/campaigns")}
          >
            <Link
              to="/campaigns"
              onClick={() => {
                sessionStorage.removeItem("campaignDashboardFilters");
              }}
            >
              <ListItem>Campaigns</ListItem>
            </Link>
          </ListGroup>
        </HeaderNav>
        <HeaderNav
          align="right"
          openDropdownOnHover
          drawer
          style={{ paddingLeft: "0px" }}
        >
          <ListGroupDropdown id="user">
            <span>Configuration</span>
            <ListGroup>
              <DropDownMenuItem
                visible={ValidatePermission("management-user")}
                to="/management-user"
                name="Users Management"
                pathname={pathname}
              />
              <DropDownMenuItem
                visible={ValidatePermission("products")}
                to="/products"
                name="Products"
                pathname={pathname}
              />
              <DropDownMenuItem
                visible={ValidatePermission("campaigns")}
                to="/promotions"
                name="Promotions"
                pathname={pathname}
              />
              <DropDownMenuItem
                visible={ValidatePermission("campaigns")}
                to="/gs1suppliercodes"
                name="GS1 Supplier Codes"
                pathname={pathname}
              />
              <DropDownMenuItem
                visible={ValidatePermission("campaigns")}
                to="/barcodeblocklists"
                name="Barcode blocklist"
                pathname={pathname}
              />
              <DropDownMenuItem
                visible={ValidatePermission("campaigns")}
                to="/legacy_campaigns"
                name="Campaigns (Legacy)"
                pathname={pathname}
              />
              <DropDownMenuItem
                visible={ValidatePermission("baserate")}
                to="/baserate"
                name="Baserate"
                pathname={pathname}
              />
              <DropDownMenuItem
                visible={ValidatePermission("allocateoffer")}
                to="/allocateoffer"
                name="Allocate offer"
                pathname={pathname}
              />
              <DropDownMenuItem
                visible={ValidatePermission("offercodes")}
                to="/offercodes"
                name="Manage offer codes"
                pathname={pathname}
              />
              <DropDownMenuItem
                visible={ValidatePermission("homepage-pods")}
                to="/homepage-pods"
                name="Homepage Pods"
                pathname={pathname}
              />
              <DropDownMenuItem
                visible={ValidatePermission("marketing-events")}
                to="/skus"
                name="Skus"
                pathname={pathname}
              />
            </ListGroup>
          </ListGroupDropdown>
        </HeaderNav>
        <HeaderDivider />
        <HeaderNav align="right" openDropdownOnHover drawer>
          <ListGroupDropdown id="user">
            <Account color="#f06c00" />
            <span className="ln-u-margin-left">
              Hi, {username.split("@")[0]}
            </span>
            <ListGroup>
              <ListGroupItem>
                <div onClick={signOutRedirect}>Logout</div>
              </ListGroupItem>
            </ListGroup>
          </ListGroupDropdown>
        </HeaderNav>
      </HeaderMainBar>
    </Header>
  );
};

const DropDownMenuItem = ({ name, to, pathname, visible, active }) => (
  <ListGroupItem
    element={Link}
    style={{ display: visible ? "" : "none" }}
    to={to}
    active={pathname.startsWith(to) || active}
  >
    {name}
  </ListGroupItem>
);

export default OurHeader;
