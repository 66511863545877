import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getSessionCookieData = () => {
  const sessionCookie = cookies.get("pianoManagementSession");
  if (!sessionCookie) {
    return null;
  }
  // The cookie is a JWT, and the middle section is all we care about
  const cookieComponents = sessionCookie.split(".");
  if (!cookieComponents || cookieComponents.length <= 1) {
    return null;
  }
  const base64Session = cookieComponents[1];
  const data = JSON.parse(atob(base64Session));

  let permissions = [];
  if (data.aud && data.aud.length > 0) {
    permissions = data.aud.split(",");
  }
  return {
    username: data.identity?.username ?? null,
    permissions,
  };
};
