const PORTAL_PROD = "management.pianorewards.com";
const redirectUri = `${window.location.protocol}//${window.location.host}/`;

export function isProd() {
  const { location: { hostname = "" } = {} } = window;
  return [PORTAL_PROD].includes(hostname);
}

const AZURE_CLIENT_ID = isProd()
  ? process.env.REACT_APP_AZURE_CLIENT_ID
  : process.env.REACT_APP_AZURE_CLIENT_ID_NON_PROD;
const AZURE_TENANT_ID = isProd()
  ? process.env.REACT_APP_AZURE_TENANT_ID
  : process.env.REACT_APP_AZURE_TENANT_ID_NON_PROD;
const IDENTITY_URL = `https://login.microsoftonline.com/${AZURE_TENANT_ID}`;

let instance, accounts;

export const setAuthInstance = (authInstance) => {
  instance = authInstance;
};

export const setAuthAccounts = (authAccounts) => {
  accounts = authAccounts;
};

export const msalConfig = {
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: IDENTITY_URL,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["User.Read", "email", "openid"],
};

export const login = async () => {
  if (!instance) {
    return;
  }

  try {
    await instance.loginRedirect(loginRequest);
  } catch (error) {
    console.error(error);
  }
};

export const logout = async () => {
  if (!instance) {
    return;
  }

  try {
    await instance.logoutRedirect();
  } catch (error) {
    console.error(error);
  }
};

export const getToken = async () => {
  if (!instance || !accounts) {
    return;
  }

  const request = {
    ...loginRequest,
    account: accounts[0],
  };

  const authExp = sessionStorage.getItem("authExpiryTime");

  if (authExp) {
    request["forceRefresh"] =
      new Date(1000 * (Number(authExp) - 300)) < new Date();
  }

  // Silently acquires an access token which is then attached to a request
  try {
    const response = await instance.acquireTokenSilent(request);
    sessionStorage.setItem(
      "authExpiryTime",
      response && response.idTokenClaims.exp
    );
    // eslint-disable-next-line consistent-return
    return response;
  } catch (error) {
    const response = await instance.acquireTokenPopup(request);
    sessionStorage.setItem(
      "authExpiryTime",
      response && response.idTokenClaims.exp
    );
    // eslint-disable-next-line consistent-return
    return response;
  }
};

export const signOutRedirect = async () => {
  await logout();
};
